import React, { useState } from 'react';
import cx from 'classnames';
import { useCurrencyTranslation } from 'hooks';
import {
  addMilliseconds,
  compareDesc,
  differenceInCalendarMonths,
  format,
  isAfter,
  isBefore,
  subMonths,
  subSeconds,
  subYears,
} from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { Link, useLocation, useParams } from 'react-router-dom';
import caretRight from '../../../icons/caret-right.svg';
import infoBlue from '../../../icons/info-blue.svg';
import { useStore } from '../../../store';
import {
  capitalisedLastValueOfUrn,
  FieldOption,
  generateDateOptionsBetweenTwoPoints,
  newMarketDate,
} from '../../../util/helper-func';
import { useBillCreation } from '../../billing/billingInvoicesApi';
import {
  BillingReadiness,
  BillingReadinessCharge,
  Period,
  useBillingReadiness,
} from '../../billing/billingReadinessApi';
import EmptyTableRow from '../../common/EmptyTableRow';
import LoadingSpinner from '../../common/LoadingSpinner';
import FieldWrapper, { FieldWidth } from '../../form/FieldWrapper';
import Card from '../../layout/Card';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../../layout/Page';
import {
  SupplyPeriodSummary,
  useConnectionDetails,
  useSupplyPeriodDetailsV3,
} from '../connectionsApi';
import { RequestRange } from './RequestRange';
import { useExternalBillingPresentation } from '../../core/coreExternalBillingApi';
import getConfig from '../../../config/getConfig';
import { endOfMonthInZone, startOfMonthInZone } from '@developers/flux-date-util';
import { formatInTimeZone } from 'date-fns-tz';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSelect,
} from '@elastic/eui';
import CalculationsCountComponent from '../dashboard/calculations/CalculationsCountComponent';
import { useRatingCalculatorMetrics } from 'components/rating-calculator/ratingsApi';
import RatingEngineField from '../dashboard/PreferredRatingEngineComponent';
import { Widget } from 'components/dashboard/Dashboard';
import RequestNewCharges from './RequestNewCharges';

const labelFormat = 'MMMM yyyy';
const valueFormat = "yyyy-MM-dd'T'HH:mm:ssXXX";
const dateFormat = 'd MMM yyyy';

type BillingReadinessRowProps = BillingReadinessCharge & {
  externalSupplyAgreementId: string;
  billingPeriod: Period;
  ratingResultId: string;
  spid?: string;
  cpid?: string;
};

const BillingReadinessRow = ({
  externalSupplyAgreementId,
  createdDate,
  count,
  isCustomerGenerated,
  ratingResultType,
  period,
  preferred,
  ratingResultId,
  latestCreditNote,
  latestInvoice,
  total,
  spid,
  cpid,
  ratingEngine,
}: BillingReadinessRowProps) => {
  const { translateCurrency } = useCurrencyTranslation();

  const createdAt = createdDate ? format(newMarketDate(createdDate), dateFormat) : '-';

  const createdAtUrl = createdDate ? format(newMarketDate(createdDate), 'yyyy-MM-dd') : '';

  const ratingFrom = period?.startsAt ? format(newMarketDate(period.startsAt), dateFormat) : '';

  const ratingTo = period?.endsAt
    ? format(subSeconds(newMarketDate(period.endsAt), 1), dateFormat)
    : '';

  const [confirmRatingEngineDiffernceModal, setConfirmRatingEngineDiffernceModal] = useState(false);

  const {
    dysn30EnableAbMode, // dysn-30-enable-ab-mode
  } = useFlags();

  const { data } = useSupplyPeriodDetailsV3(spid, {
    enabled: !!spid,
  });

  const { mutateAsync, error, isLoading } = useBillCreation(ratingResultId);

  if (error) {
    let errorText = error;
    if (!(error instanceof String)) {
      errorText = JSON.stringify(error);
    }
    console.error(new Error(errorText as string));
  }

  const formattedTotalAmount = translateCurrency(total?.amount);

  const latestActivity = latestCreditNote
    ? latestCreditNote.title
    : latestInvoice
    ? latestInvoice.title
    : '-';

  const billingReadinessRowParams = new URLSearchParams();
  billingReadinessRowParams.set('date', createdAtUrl);
  billingReadinessRowParams.set('agreementId', externalSupplyAgreementId);
  if (spid) {
    billingReadinessRowParams.set('spid', spid);
  }
  if (cpid) {
    billingReadinessRowParams.set('cpid', cpid);
  }
  function onInvoiceCreate() {
    if (dysn30EnableAbMode && data && ratingEngine !== data.preferredRatingEngine) {
      setConfirmRatingEngineDiffernceModal(true);
      return undefined;
    } else {
      return mutateAsync();
    }
  }

  return (
    <>
      <tr className="apl-table-v1__row" data-testid={`billing-readiness-row-${ratingResultId}`}>
        <td>
          <Link
            data-testid="charge details link"
            className="apl-color-primary"
            to={`${ratingResultId}?${billingReadinessRowParams.toString()}`}
          >
            {createdAt}
          </Link>
        </td>
        <td>{count}</td>
        <td>{`${ratingFrom} - ${ratingTo}`}</td>
        <td>{`${formattedTotalAmount}`}</td>
        <td>{preferred ? 'Preferred' : ''}</td>
        <td>{ratingResultType === 'finance' ? 'finance' : isCustomerGenerated ? 'out' : 'in'}</td>
        <td>{latestActivity}</td>
        {dysn30EnableAbMode && <td>{ratingEngine && capitalisedLastValueOfUrn(ratingEngine)}</td>}
        <td>
          <button
            className={cx(
              'apl-button-v1',
              'apl-display-flex',
              'apl-flex-row',
              'apl-align-items-center'
            )}
            // disable the create invoice button, if there is an invoice already
            // Before the feature flag, we used the object to check that there is no invoice.
            disabled={latestInvoice && !latestCreditNote}
            onClick={() => onInvoiceCreate()}
          >
            Create invoice
            {isLoading && <LoadingSpinner extraClasses="apl-ml-xs" />}
          </button>
        </td>
      </tr>
      {confirmRatingEngineDiffernceModal && (
        <EuiModal onClose={() => setConfirmRatingEngineDiffernceModal(false)}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Rating Engine Mismatch</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            {data &&
              ratingEngine &&
              'This charge set was calculated by the ' +
                capitalisedLastValueOfUrn(ratingEngine) +
                ' rating engine, which differs from the current preferred engine setting, ' +
                capitalisedLastValueOfUrn(data.preferredRatingEngine) +
                '. Are you sure you want to proceed and manually generate an invoice with this charge set?'}
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={() => setConfirmRatingEngineDiffernceModal(false)}
              disabled={isLoading}
              type="button"
            >
              Cancel
            </EuiButton>
            <EuiButton
              fill
              isDisabled={isLoading}
              data-testid="modal-comfirm"
              onClick={async () => {
                setConfirmRatingEngineDiffernceModal(false);
                return await mutateAsync();
              }}
            >
              Create Invoice
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
};

interface BillingReadinessChargeRow {
  charge: BillingReadinessCharge;
  connection: string;
  externalSupplyAgreementId: string;
  period: Period;
  ratingEngine?: string;
}

interface BillingReadinessChargeRowProps {
  chargeRows: BillingReadinessChargeRow[];
  spid?: string;
  cpid?: string;
}

const BillingReadinessChargeRows = ({ chargeRows, spid, cpid }: BillingReadinessChargeRowProps) => {
  return (
    <>
      {chargeRows.map((chargeRow) => {
        const { externalSupplyAgreementId, period } = chargeRow; // billing

        // charge
        const ratingId = chargeRow.charge.href.split('/').pop();
        return (
          <BillingReadinessRow
            externalSupplyAgreementId={externalSupplyAgreementId}
            key={`billing-readiness-row-${ratingId}`}
            billingPeriod={period}
            ratingResultId={ratingId as string}
            spid={spid}
            cpid={cpid}
            {...chargeRow.charge}
          />
        );
      })}
    </>
  );
};

const ChargeSets = () => {
  const { search } = useLocation();
  const query = search ? new URLSearchParams(search) : null;

  const { id } = useParams<{ id: string | undefined }>();
  const {
    dysn30EnableAbMode, //dysn-30-enable-ab-mode
    dysn45RatingEngineConfig, //dysn-45-rating-engine-config
    lobi627NewChargeSetsDropdownUi, //lobi-627-new-charge-sets-dropdown-ui
  } = useFlags();
  const {
    data: connectionData,
    isError: connectionDataError,
    isInitialLoading: connectionDataLoading,
  } = useConnectionDetails(id);
  const connectionId = connectionData?.[0] ? connectionData[0].connectionId : id;

  const {
    data: calculations,
    isError: isCalculationsError,
    isInitialLoading: isCalculationsLoading,
  } = useRatingCalculatorMetrics(connectionData && connectionData.id);

  // setup from and to date options
  const queryFrom = query?.get('from');
  const queryTo = query?.get('to');
  const availableQueryFrom = startOfMonthInZone(
    queryFrom ? new Date(queryFrom) : subYears(new Date(), 2),
    getConfig().marketTimezone
  );
  const availableDateTo = addMilliseconds(
    endOfMonthInZone(queryTo ? new Date(queryTo) : new Date(), getConfig().marketTimezone),
    1
  );

  const fromOptions = generateDateOptionsBetweenTwoPoints(
    availableQueryFrom,
    availableDateTo,
    true,
    labelFormat,
    valueFormat,
    false
  );
  const toOptions = generateDateOptionsBetweenTwoPoints(
    availableQueryFrom,
    availableDateTo,
    false,
    labelFormat,
    valueFormat,
    true
  ).reverse();

  // calculate the limit base from the maximum date range that can be selected. this will be
  // the number of months between the earliest "From" option and the latest "To" option.
  const limit = Math.abs(differenceInCalendarMonths(availableDateTo, availableQueryFrom)) + 1;

  // default the initial start date to the max of today - 2 months and the supply period start date
  const twoMonthsAgo = startOfMonthInZone(
    subMonths(availableDateTo, 3),
    getConfig().marketTimezone
  );
  const availableDateFrom = isBefore(availableQueryFrom, twoMonthsAgo)
    ? twoMonthsAgo
    : availableQueryFrom;

  // collect all externalSupplyAgreementIds for the given owner/contracted party
  const contractedPartyId = query && query.get('contracted_party');

  const externalSupplyAgreementIds: string[] =
    !connectionDataLoading &&
    connectionData &&
    contractedPartyId &&
    connectionData?.[0]?.supplyPeriods
      ?.filter((period: SupplyPeriodSummary) => period.owner === contractedPartyId)
      .map((period: SupplyPeriodSummary) => period.externalSupplyAgreementId);
  const [from, setFrom] = useState<Date>(availableDateFrom);
  const [to, setTo] = useState<Date>(availableDateTo);
  const [requestDates, setRequestDates] = useState<RequestRange>();

  // load billing readiness data
  const {
    data: billingReadinessData,
    isError: isBillingReadinessError,
    isInitialLoading: isBillingReadinessLoading,
  } = useBillingReadiness(
    {
      id: id,
      startDate: format(from, valueFormat),
      endDate: format(to, valueFormat),
      totalsOnly: false,
      externalSupplyAgreementIds: externalSupplyAgreementIds,
      limit: limit,
    },
    {
      enabled: !!id && !!externalSupplyAgreementIds,
    }
  );

  // create a new array which will be used to collect all charges for each billing readiness items
  const chargeRows: BillingReadinessChargeRow[] = [];
  billingReadinessData?.items.forEach((item: BillingReadiness) => {
    item.charges.forEach((charge: BillingReadinessCharge) => {
      // for each charge rows, we need to merge some data from the parent "item" object (connection, externalSupplyAgreemntId etc)
      const chargeObject: BillingReadinessChargeRow = {
        charge: { ...charge },
        connection: item.connection,
        externalSupplyAgreementId: item.externalSupplyAgreementId,
        period: item.period,
      };

      chargeRows.push(chargeObject);
    });
  });

  // sort base from charge.createdDate value
  chargeRows.sort((chargeA: BillingReadinessChargeRow, chargeB: BillingReadinessChargeRow) =>
    compareDesc(new Date(chargeA.charge.createdDate), new Date(chargeB.charge.createdDate))
  );

  // IMPORTANT: Currently the update of a supplyPeriod is not working properly. The following
  // code is to get the contractedParty via a separate API call to get the updated value. Core
  // is not sending an update event in case the contractedParty (and others?) gets updated.
  // If the above issue is fixed we can then use the connectionData object which we have anyway.
  // see:https://jira.fluxfederation.com/browse/MX-11215
  const periodAgreementId = externalSupplyAgreementIds?.[0];
  const { data: externalData } = useExternalBillingPresentation(periodAgreementId ?? '', {
    enabled: !!periodAgreementId,
  });

  // Switch the following lines if the currently flaky supplyPeriod update from Core is working
  // const supplyPeriod = connectionData[0]?.supplyPeriods[0]
  const contractedParty =
    externalData && externalData?.parties?.owner?.name ? externalData.parties.owner.name : '-';

  if (connectionDataLoading) {
    return <LoadingPage />;
  }

  if (connectionDataError) {
    return <ErrorPage />;
  }

  const reqStartAt = requestDates ? format(new Date(requestDates.startAt), dateFormat) : null;
  const reqEndAt = requestDates ? format(new Date(requestDates.endAt), dateFormat) : null;

  // determine if there are charge sets available. Check if there is at
  // least one item with charges. If there is none, this flag should be set to false and the
  // "Sorry, there are no charge sets for this period" should be displayed.
  const hasChargeSets =
    billingReadinessData?.items?.length > 0 &&
    billingReadinessData.items.some((item: BillingReadiness) => item.charges?.length > 0);

  const spid = query && query.get('spid');
  const supplyPeriod = spid
    ? connectionData[0]?.supplyPeriods.filter(
        (period: any) => period.status == 'VALID' && period.id == spid
      )[0]
    : connectionData[0]?.supplyPeriods[0];
  return (
    <>
      <PageHeader
        title={() => (
          <>
            <Link
              className="page__title-link"
              to={`/connections/${id}/supply-periods/${spid}/contracted-parties/${contractedPartyId}`}
            >
              {connectionId}
            </Link>
            <img src={caretRight} alt="" />
            Charge sets
          </>
        )}
      >
        <RequestNewCharges
          supplyPeriod={supplyPeriod}
          id={id ?? ''}
          updateFunction={setRequestDates}
        />
      </PageHeader>
      <Page>
        {requestDates && (
          <Card className="apl-mb-l">
            <div
              className={cx('apl-display-flex', 'apl-flex-row', 'apl-align-items-center')}
              style={{ padding: '5px 2px' }}
            >
              <img className="apl-mr-l" src={infoBlue} />
              <p className="apl-my-none" style={{ fontSize: '14px' }}>
                {`New charge sets from ${reqStartAt} to ${reqEndAt} have been requested. Please review again later.`}
              </p>
            </div>
          </Card>
        )}
        <Widget width="full" className={cx('apl-display-flex', 'apl-flex-grow-1', 'widget--full')}>
          <div className={cx('apl-display-flex', 'apl-flex-grow-1')}>
            <div className="apl-field-v1 apl-display-flex apl-flex-column apl-align-items-start">
              <label className="apl-field__label apl-mr" htmlFor="contracted-party-field">
                Contracted party
              </label>
              <p className="apl-my-none apl-h3" id="contracted-party-field">
                {contractedParty}
              </p>
            </div>
          </div>
          <div className={cx('apl-display-flex')} style={{}}>
            {dysn45RatingEngineConfig && (
              <RatingEngineField
                id={spid ?? ''}
                onStatusUpdate={useStore.getState().addNotification}
              />
            )}
            {getConfig().showCalculationCountOnConnectionScreen && (
              <CalculationsCountComponent
                calculations={calculations}
                isCalculationsError={isCalculationsError}
                isCalculationsLoading={isCalculationsLoading}
              />
            )}
          </div>
        </Widget>
        <Widget className="apl-align-items-center apl-my-s">
          {lobi627NewChargeSetsDropdownUi ? (
            <EuiFlexGroup gutterSize="m" style={{ marginBottom: '15px' }}>
              <EuiFlexItem grow={0}>
                <EuiSelect
                  fullWidth
                  isInvalid={!isAfter(to, from)}
                  prepend={'From start of'}
                  data-testid="startSelect"
                  options={fromOptions.map((option) => {
                    return { text: option.label, value: option.value };
                  })}
                  value={formatInTimeZone(from, getConfig().marketTimezone, valueFormat)}
                  onChange={(element) => setFrom(new Date(element.target.value))}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={0}>
                <EuiSelect
                  fullWidth
                  isInvalid={!isAfter(to, from)}
                  prepend={'To end of'}
                  data-testid="endSelect"
                  options={toOptions.map((option) => {
                    return { text: option.label, value: option.value };
                  })}
                  onChange={(element) => setTo(new Date(element.target.value))}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : (
            <div className="table-filter">
              <Formik
                initialValues={{
                  chargesFrom: formatInTimeZone(
                    availableDateFrom,
                    getConfig().marketTimezone,
                    valueFormat
                  ),
                  chargesTo: to,
                }}
                onSubmit={({ chargesFrom, chargesTo }) => {
                  if (chargesFrom) {
                    setFrom(new Date(chargesFrom));
                  }

                  if (chargesTo) {
                    setTo(new Date(chargesTo));
                  }
                }}
              >
                {() => (
                  <Form
                    className={cx(
                      'form--inline',
                      'form--connection-charges-summary',
                      'apl-display-flex',
                      'apl-flex-row'
                    )}
                  >
                    <FieldWrapper
                      className={cx('apl-display-flex', 'apl-flex-row', 'apl-align-items-center')}
                      htmlFor="charges-from"
                      label="Filter billing period:"
                      fieldWidth={FieldWidth.FULL}
                    >
                      <Field
                        autoComplete="off"
                        className="apl-select-v1_0"
                        data-testid="charges-from-field"
                        name="chargesFrom"
                        id="charges-from"
                        as="select"
                      >
                        {fromOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </FieldWrapper>
                    <FieldWrapper
                      className={cx('apl-display-flex', 'apl-flex-row', 'apl-align-items-center')}
                      htmlFor="charges-to"
                      label="to"
                      fieldWidth={FieldWidth.FULL}
                    >
                      <Field
                        autoComplete="off"
                        className="apl-select-v1_0"
                        data-testid="charges-to-field"
                        name="chargesTo"
                        id="charges-to"
                        as="select"
                      >
                        {toOptions.map((option: FieldOption) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </FieldWrapper>
                    <button type="submit" className="apl-button-v1">
                      Filter
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {isBillingReadinessLoading ? <LoadingPage /> : null}
          {isBillingReadinessError ? <ErrorPage /> : null}
          {billingReadinessData && (
            <table className="apl-table-v1 apl-width-full">
              {hasChargeSets && (
                <>
                  <thead>
                    <tr>
                      <th>Date created</th>
                      <th>Charges</th>
                      <th>Billing period</th>
                      <th>Total amount</th>
                      <th>Quality</th>
                      <th>Type</th>
                      <th>Latest activity</th>
                      {dysn30EnableAbMode && <th>Rating Engine</th>}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <BillingReadinessChargeRows
                      chargeRows={chargeRows}
                      spid={spid ? spid : undefined}
                      cpid={contractedPartyId ? contractedPartyId : undefined}
                    />
                  </tbody>
                </>
              )}
              {!hasChargeSets && (
                <EmptyTableRow message="Sorry, there are no charge sets for this period" />
              )}
            </table>
          )}
        </Widget>
      </Page>
    </>
  );
};

export default ChargeSets;
