import { RatingCalculation } from '../../rating-calculator/ratingsApi';
import { Period } from '../../../util/helper-func';
import { PublishedError } from '../../errors/errorsApi';

interface ConnectionPageError {
  title: string;
  body: string;
  date: string;
}

export function mappedConnectionPageErrors(
  ratingErrors: RatingCalculation[] | undefined,
  ratingErrorsIsSuccess: boolean,
  publishedErrors: PublishedError[] | undefined,
  publishedErrorsIsSuccess: boolean,
  limit: number
): ConnectionPageError[] {
  let connectionPageErrors: ConnectionPageError[] = [];
  if (publishedErrorsIsSuccess && publishedErrors) {
    connectionPageErrors = latestNFromPublishedErrors(publishedErrors, limit);
  }
  if (ratingErrorsIsSuccess && ratingErrors) {
    connectionPageErrors = connectionPageErrors
      .concat(latestNFromRatingErrors(ratingErrors, limit))
      .sort((a, b) => sortPublishedErrors(a, b));
  }
  return connectionPageErrors;
}

function latestNFromRatingErrors(
  ratingErrors: RatingCalculation[],
  n: number
): ConnectionPageError[] {
  return latestN(
    ratingErrors.map((ce) => fromRatingError(ce)),
    n
  );
}

function latestNFromPublishedErrors(
  publishedErrors: PublishedError[],
  n: number
): ConnectionPageError[] {
  return latestN(
    publishedErrors.map((ce) => fromPublishedError(ce)),
    n
  );
}

function sortPublishedErrors(thisOne: ConnectionPageError, thatOne: ConnectionPageError) {
  return new Date(thatOne.date).getTime() - new Date(thisOne.date).getTime();
}

function fromPublishedError(error: PublishedError): ConnectionPageError {
  return {
    title: error.source,
    body: error.message ?? 'no message',
    date: error._publishedAt,
  };
}

function fromRatingError(error: RatingCalculation): ConnectionPageError {
  return {
    title: ratingErrorTitle(error),
    body: error.errors?.join('\n'),
    date: error.context.requestedAt,
  };
}

function latestN(errors: ConnectionPageError[], n: number): ConnectionPageError[] {
  return errors.sort((a, b) => sortPublishedErrors(a, b)).slice(0, n);
}

function ratingErrorTitle(error: RatingCalculation) {
  return (
    'Rating error (' +
    new Period(error.context.startsAt, error.context.endsAt).toFormattedRatingPeriod() +
    ')'
  );
}

export default ConnectionPageError;
